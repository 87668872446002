import { ErrorType } from '@/store/ErrorHandleStore'
import { CustomDialog, CustomDialogTitle } from '@local/ui'
import { Button, DialogActions, DialogContent, Stack, SxProps, Typography } from '@mui/material'

export const rootSx: SxProps = {
  '& .MuiTextField-root': {
    '& .MuiInputBase-root': { borderRadius: 0 },
    width: '100%',
  },
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

export type ErrorViewDialogProps = {
  open: boolean
  onClose: () => void
  errorMsg: ErrorType | null
}

export default function ErrorViewDialog(props: ErrorViewDialogProps) {
  const { open, onClose, errorMsg } = props
  const handleCloseDialog = () => {
    onClose()
  }

  const tempErrMsg =
    errorMsg?.code === 'E1_HTTP_500' ? '서버 오류가 발생했습니다.' : errorMsg?.message
  return (
    <CustomDialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
      sx={rootSx}
      className="ErrorViewDialog-root"
    >
      <CustomDialogTitle title="시스템 오류" onClose={handleCloseDialog}>
        {' '}
        <Typography
          pl={2}
          variant="body1"
          sx={{ em: { fontStyle: 'normal', color: 'error.main' } }}
        >
          <em>시스템 오류</em>가 발생했습니다. 관리자에게 문의하세요.
        </Typography>
      </CustomDialogTitle>
      <DialogContent dividers>
        {!errorMsg ? (
          <>
            <Typography sx={{ fontWeight: 600 }} variant="subtitle2">
              등록된 에러가 없습니다
            </Typography>
          </>
        ) : (
          <>
            {' '}
            <Stack mb={1.5} spacing={1} direction="row" alignItems="center">
              <Typography sx={{ fontWeight: 600 }} variant="subtitle2">
                에러 코드 :
              </Typography>
              <Typography>{errorMsg.code}</Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography sx={{ fontWeight: 600 }} variant="subtitle2">
                에러 메세지 :
              </Typography>
              <Typography>{tempErrMsg}</Typography>
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>닫기</Button>
      </DialogActions>
    </CustomDialog>
  )
}
