import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { JisuTest, PagerData } from '..'
/**
 * 지수 연습 API
 */

export class JisuTestApi {
    private withData: ApiHelperWithData
    constructor(private helper: ApiHelper) {
      this.withData = new ApiHelperWithData(helper)
    }

    //테스트 데이터 조회
    testList = (params: BaseRequest): Promise<{ list: JisuTest[] }> => {
        const url = '/p/api/jisu-test/jisu-not-page'
        return this.withData.postJson(url, ...splitParams(params))
      }

    //테스트 데이터 조회 - 페이징
    list = (
        params: {
            testName: string
        } & BaseRequest, //기본 요청 데이터 타입 상속
    ): Promise<{ listData: JisuTest[]}> => {
      const url = '/p/api/jisu-test/list'

      return this.withData.postJson(url, ...splitParams(params))

    }


}