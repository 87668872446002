import config from '@/config'
import { UserMainApi } from '@local/domain'
import React, { createContext, useContext } from 'react'
import { apiResponseInterceptor, errorResponseInterceptor } from './apiResponseInterceptor'
import createApiHeader from './createApiHeader'

export const api = new UserMainApi(
  config.apiBaseURL,
  createApiHeader,
  apiResponseInterceptor,
  errorResponseInterceptor,
)

export const useApi = () => api

/**
 * API hook
 */
// export const useApi = () => useContext(ApiContext)

// type Props = {
//   children?: React.ReactNode
// }

// /**
//  * api provider
//  */
// export default function ApiProvider(props: Props) {
//   const { children } = props
//   return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
// }
