import { makeAutoObservable } from 'mobx'

export type ErrorType = {
  code: string
  message: string
}
/**
 * 에러메세지를 보관하는 mobx 스토어
 */
class ErrorHandleStore {
  error: ErrorType | null = null
  constructor() {
    // 자동 메서드 생성
    makeAutoObservable(this, {
      error: true, // error를 observable로 설정
      setError: true,
      clearError: true,
    })
  }
  // 에러 등록
  setError(code: string, message: string) {
    this.error = { code, message }
  }
  // 에러 클리어링
  clearError = () => {
    this.error = null
  }
}

const errorHandleStore = new ErrorHandleStore()
export default errorHandleStore
