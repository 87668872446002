import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { EduExpTb } from '../model'

/**
 * 컬럼 정의서 API
 */
export class EduExpTbApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 목록 조회
   */
  list = (
    params: {
      eduTitle?: string
    } & BaseRequest,
  ): Promise<{ listData: EduExpTb[] }> => {
    const url = '/p/api/edu-exp-tb/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 단건 조회
   */
  info = (params: { eduId: number } & BaseRequest): Promise<{ listData: EduExpTb }> => {
    const url = '/p/api/edu-exp-tb/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 등록
   */
  insert = (
    params: {
      eduTitle: string
      eduExpl: string
    } & BaseRequest,
  ): Promise<{ insertCnt: number }> => {
    const url = '/p/api/edu-exp-tb/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 수정
   */
  update = (
    params: {
      eduTitle: string
      eduExpl: string
      eduId: number
    } & BaseRequest,
  ): Promise<{ uptCnt: number }> => {
    const url = '/p/api/edu-exp-tb/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 삭제
   */
  delete = (
    params: {
      eduId: number
    } & BaseRequest,
  ): Promise<{ delCnt: number }> => {
    const url = '/p/api/edu-exp-tb/delete'
    return this.withData.post(url, ...splitParams(params))
  }
}
