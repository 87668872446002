import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { JeongSeoCode, JeongSeoSearchList, objDeleteParam } from '..'



/**
 * 컬럼 정의서 API
 */
export class JeongSeoListApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

   /**
   * 목록 조회
   */
   searchList = (
    params: {
      lfieldId?: number
      sfieldName? :string
    } & BaseRequest,
  ): Promise<{ listData: JeongSeoSearchList[] }> => {
    const url = '/p/api/jeong-seo/search-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

 /**
   * 코드 조회
   */
 codeList = (
  params: {
    stranslation?: string,
    sfieldName?: string,
    lfieldId?: number,
    svalue?: string
  } & BaseRequest,
): Promise<{ listData: JeongSeoCode[] }> => {
  const url = '/p/api/jeong-seo/code-list'
  return this.withData.postJson(url, ...splitParams(params))
}

 /**
   * 등록
   */
 insert = (
  params: {
    fieldId: number,
    fieldName: string,
  } & BaseRequest,
): Promise<{ insertCnt: number }> => {
  const url = '/p/api/jeong-seo/field-insert'
  return this.withData.postJson(url, ...splitParams(params))
}


 /**
   * 등록
   */
 fieldUpdate = (
  params: {
    fieldId: number,
    fieldName: string,
  } & BaseRequest,
): Promise<{ insertCnt: number }> => {
  const url = '/p/api/jeong-seo/field-update'
  return this.withData.postJson(url, ...splitParams(params))
}

 /**
   * 수정
   */
 codeUpdate = (
  params: {
    fieldId: number,
    translation: string,
    svalue:string
  } & BaseRequest,
): Promise<{ insertCnt: number }> => {
  const url = '/p/api/jeong-seo/code-update'
  return this.withData.postJson(url, ...splitParams(params))
}

 /**
   * 등록
   */
  codeInsert = (
  params: {
    fieldId: number,
    translation: string,
    svalue:string
  } & BaseRequest,
): Promise<{ insertCnt: number }> => {
  const url = '/p/api/jeong-seo/code-insert'
  return this.withData.postJson(url, ...splitParams(params))
}
 /**
   * 삭제
   */

 codeDelete = (
  params: {list:objDeleteParam[]} & BaseRequest,
): Promise<{ insertCnt: number }> => {
  const url = '/p/api/jeong-seo/code-delete'
  return this.withData.postJson(url, ...splitParams(params))
}

 /**
   * 삭제
   */

 fieldDelete = (
  params: {
    tmpObj: number[]
  } & BaseRequest,
): Promise<{ insertCnt: number }> => {
  const url = '/p/api/jeong-seo/field-delete'
  return this.withData.post(url, ...splitParams(params))
}




}
