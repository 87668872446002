import { Accordion, AccordionProps, SxProps, Theme } from '@mui/material'
import { flatSx } from './../lib/sx-props'

type Props = {} & AccordionProps
const rootSx: SxProps<Theme> = {
  boxShadow: 'none',
}
/**
 * 생성자 : sangbinLee
 * 전체 아코디언
 * children 내부에 AccordionSummary(제목) AccordionDetails(내용) 이 존재해야 한다.
 */
export function CLStyledAccordion(props: Props) {
  const { sx, children, ...rest } = props

  return (
    <Accordion sx={flatSx(rootSx, sx)} {...rest} disableGutters>
      {children}
    </Accordion>
  )
}
