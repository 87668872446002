import errorHandleStore from '@/store/ErrorHandleStore'
import { useCallback, useEffect, useState } from 'react'
import ErrorViewDialog, { ErrorViewDialogProps } from './components/ErrorViewDialog'
import { observer } from 'mobx-react-lite'
/**
 * 에러 처리 컴포넌트
 */

type DialogId = 'ErrorViewDialog'
const ErrorObserveProvider = observer(() => {
  const { clearError, error } = errorHandleStore

  const [errorViewDialogProps, setErrorViewDialogProps] = useState<ErrorViewDialogProps>()
  const [dialogId, setDialogId] = useState<DialogId>()

  const handleCloseDialog = useCallback(() => {
    setDialogId(undefined)
    setErrorViewDialogProps(undefined)
    clearError()
  }, [clearError])

  useEffect(() => {
    setDialogId('ErrorViewDialog')

    setErrorViewDialogProps({
      errorMsg: error,
      open: true,
      onClose: handleCloseDialog,
    })
  }, [handleCloseDialog, error])

  if (!error) {
    return null
  }
  if (dialogId === 'ErrorViewDialog' && errorViewDialogProps) {
    return <ErrorViewDialog {...errorViewDialogProps} />
  }
  return null
})

export default ErrorObserveProvider
