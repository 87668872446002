import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { PagerData, Team, TeamForUser } from '../model'

/**
 *  팀 API
 */
export class TeamApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 팀 목록 조회
   */
  list = (
    params: {
      teamNm: string
    } & BaseRequest,
  ): Promise<{ teamList: Team[] }> => {
    const url = '/apis/team/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 팀 신규 등록
   */
  create = (
    params: {
      teamNm: string // pk
      teamExpl?: string
      psnlStupAcceYn: 'Y' | 'N'
      teamCmmnStupCn?: string
      teamStat?: number
      teamTskClsf?: number
      regrId: string
      chgrId: string
    } & BaseRequest,
  ): Promise<{
    instCnt: number
  }> => {
    const url = '/apis/team/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 팀 조회
   */
  info = (
    params: {
      teamId: number
    } & BaseRequest,
  ): Promise<{
    team: Team
  }> => {
    const url = '/apis/team/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 팀 수정
   */
  update = (
    params: {
      teamId: number
      teamNm: string
      teamExpl?: string
      teamTskClsf?: number
    } & BaseRequest,
  ): Promise<{
    uptCnt: number
  }> => {
    const url = '/apis/team/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 팀별 사용자 목록
   */
  teamForUser = (
    params: {
      teamId: number
    } & BaseRequest,
  ): Promise<{ teamForUser: TeamForUser[] }> => {
    const url = '/p/api/team/user-list'
    return this.withData.post(url, ...splitParams(params))
  }
}
