import { ApiHelper, ErrorResponseInterceptor, ResponseInterceptor } from '@clover/js-api-common'
import { errorToMessage } from './errors/error-messages'
import log from './log'
import { AuthApi } from './user-apis/AuthApi'
import { CLApiPageApi } from './user-apis/CLApiPageApi'
import { CLCodeApi } from './user-apis/CLCodeApi'
import { CLCodeTypeApi } from './user-apis/CLCodeTypeApi'
import { CLEtcApi } from './user-apis/CLEtcApi'
import { CLJobApi } from './user-apis/CLJobApi'
import { CLNavApi } from './user-apis/CLNavApi'
import { CLPrivApi } from './user-apis/CLPrivApi'
import { CLRoleApi } from './user-apis/CLRoleApi'
import { CLRoleUserApi } from './user-apis/CLRoleUserApi'
import { CLSystemLogApi } from './user-apis/CLSystemLogApi'
import { ColmMppnDefnApi } from './user-apis/ColmMppnDefnApi'
import { MetaApi } from './user-apis/MetaApi'
import { MetaGlossaryApi } from './user-apis/MetaGlossaryApi'
import { PdsApi } from './user-apis/PdsApi'
import { ProfileApi } from './user-apis/ProfileApi'
import { TeamApi } from './user-apis/TeamApi'
import { UserApi } from './user-apis/UserApi'
import { UserManageApi } from './user-apis/UserManageApi'
import { CLErrCodeApi } from './user-apis/CLErrCodeApi'
import { EduExpTbApi } from './user-apis/EduExpTbApi'
import { JisuTestApi } from './user-apis/JisuTestApi'
import { JeongSeoListApi } from './user-apis/JeongSeoListApi'



/**
 * model 디렉토리에서 응답데이터의 타입을 정의하고 model/index.ts에서 생성한 ts파일 추가
 * user-apis 디렉토리에서 API 클래스 생성후 서버요청의 정보에 맞는 메서드 생성
 * UserMainApi 만든 클래스 타입의 변수를 생성 후에 this. helper 객체 파라미터로 전달
 * UserMainApi 클래스는 개발자 공통 작업이 일어날수 있어 충돌 주의
 */
export class UserMainApi {
  // user-apis디렉토리에서 만든 클래스를 타입으로 변수 생성
  helper: ApiHelper
  // begin clover framework apis
  clCode: CLCodeApi
  clCodeType: CLCodeTypeApi
  clJob: CLJobApi
  clSystemLog: CLSystemLogApi
  clEtc: CLEtcApi
  clNav: CLNavApi
  clPriv: CLPrivApi
  clRole: CLRoleApi
  clRoleUser: CLRoleUserApi
  clPageApi: CLApiPageApi
  clErrCode: CLErrCodeApi
  // end clover framework apis
  auth: AuthApi
  meta: MetaApi
  pds: PdsApi
  user: UserApi
  userManage: UserManageApi
  team: TeamApi
  glossary: MetaGlossaryApi
  profile: ProfileApi
  mppnDefn: ColmMppnDefnApi
  eduExpTb: EduExpTbApi
  jeongSeoListApi :JeongSeoListApi

  //지수 연습
  jisuTest: JisuTestApi

  constructor(
    public apiBaseURL: string,
    createApiHeader: () => Record<string, string>,
    responseInterceptor: ResponseInterceptor,
    errorResponseInterceptor: ErrorResponseInterceptor,
    debug = false,
  ) {
    log.debug('create Api for ', apiBaseURL)

    // create helper
    // axios 래핑 시켜주는 변수
    const helper = new ApiHelper(
      apiBaseURL,
      createApiHeader,

      responseInterceptor,
      errorResponseInterceptor,
      errorToMessage,
      debug,
    )

    //  helper를 클래스의 파라미터로 담아 생성자로 적용
    this.helper = helper

    // begin clover framework apis
    this.clCode = new CLCodeApi(helper)
    this.clCodeType = new CLCodeTypeApi(helper)
    this.clJob = new CLJobApi(helper)
    this.clSystemLog = new CLSystemLogApi(helper)
    this.clEtc = new CLEtcApi(helper)
    this.clNav = new CLNavApi(helper)
    this.clPriv = new CLPrivApi(helper)
    this.clRole = new CLRoleApi(helper)
    this.clRoleUser = new CLRoleUserApi(helper)
    this.clPageApi = new CLApiPageApi(helper)
    this.clErrCode = new CLErrCodeApi(helper)
    // end clover framework apis

    this.auth = new AuthApi(helper)
    this.meta = new MetaApi(helper)
    this.pds = new PdsApi(helper)
    this.user = new UserApi(helper)
    this.userManage = new UserManageApi(helper)
    this.team = new TeamApi(helper)
    this.glossary = new MetaGlossaryApi(helper)
    this.profile = new ProfileApi(helper)
    this.mppnDefn = new ColmMppnDefnApi(helper)
    this.eduExpTb = new EduExpTbApi(helper)

    //지수 연습
    this.jisuTest = new JisuTestApi(helper)
    this.jeongSeoListApi = new JeongSeoListApi(helper)
  }
}
