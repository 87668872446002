import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { CLErrCode, PagerData } from '..'

/**
 * clover framework 기타 API
 */
export class CLErrCodeApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 에러 코드 목록 조회
   */
  listNotPage = (params: BaseRequest): Promise<{ list: CLErrCode[] }> => {
    const url = '/api/err-code/list-not-page'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 에러 코드 목록 조회 - 페이징
   */
  // api 유추할 명칭으로 메서드 생성
  list = (
    // 파라미터 요청 데이터 타입 지정
    params: {
      rowsPerPage: number
      pageNumber: number
      msgId?: string
      msg?: string
      type?: string
    } & BaseRequest, // 기본 요청 데이터 타입 상속
    // promise 리턴 응답데이터
    // 응답의 body 영역의 이름과 일치하게 지정 -> 데이터의 타입 인터페이스
  ): Promise<{ pagerData: PagerData<CLErrCode> }> => {
    // 서버의 postMapping의 url을 가진 변수 생성
    const url = '/apis/err-code/list'
    // json 객체로 값을 넘겨주기 위해 postJson 메서드를 사용
    // splitParams : params 데이터의 ctx,header의 정보를 제외한 요청 데이터만 전달
    // 지정된 URL로 요청을 보내고 반환된 응답 데이터를 Promise로 래핑하여 반환합니다.
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 에러 코드 단건 조회
   */
  info = (
    params: {
      errKey: number
    } & BaseRequest,
  ): Promise<{ clErrCode: CLErrCode }> => {
    const url = '/apis/err-code/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 에러 코드 등록
   */
  insert = (
    params: {
      msgId: string
      msg: string
      type: string
    } & BaseRequest,
  ): Promise<{ instCnt: number }> => {
    const url = '/apis/err-code/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 에러 코드 수정
   */
  update = (
    params: {
      msgId: string
      msg: string
      type: string
      errKey: number
    } & BaseRequest,
  ): Promise<{ uptCnt: number }> => {
    const url = '/apis/err-code/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 에러 코드 삭제
   */
  delete = (
    params: {
      errKey: number
    } & BaseRequest,
  ): Promise<{ delCnt: number }> => {
    const url = '/apis/err-code/delete'
    return this.withData.post(url, ...splitParams(params))
  }
}
