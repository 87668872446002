// 로그인 페이지
export const LOGIN_PAGE = '/login'

// 로그아웃 페이지
export const LOGOUT_PAGE = '/logout'

// 비밀번호 변경 페이지
export const PW_MUST_CHANGE = '/pwd-must-change'

// 개발자 테스트용, API 디버깅 - 운영서버에서는 false
export const DEBUG_API = false

// site meta
export const SITE_META = {
  title: 'CLOVER Framework',
  image: '/images/clover-intro/clover_thumbnail.png',
  url: 'https://zero.labcl.net',
  description: 'IT 기업 크레디트라인',
}
